// Here you can add other styles

img {
  object-fit: cover;
}
select option {
  height: 35px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background: none;
}

.uppy-Dashboard-poweredBy {
  display: none !important;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

//// CORE UI EXTENSION STYLES ////

.fs-7 {
  font-size: 0.875rem !important;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.input-group-text {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

///TAILWIND STYLES////

.text-white {
  color: white !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.font-bold {
  font-weight: 600 !important;
}
.font-medium {
  font-weight: 500 !important;
}

////DRAFT JS STYLES////

.rdw-option-wrapper:hover {
  box-shadow: 0px 0px 0px 2px #00000082 !important;
}

.rdw-option-active {
  box-shadow: 0px 0px 0px 2px #00000082 !important;
}

.rdw-option-wrapper {
  transition: all 150ms ease-in-out;
}

.rdw-dropdown-wrapper {
  transition: all 150ms ease-in-out;
}

.rdw-dropdown-wrapper:hover {
  box-shadow: 0px 0px 0px 2px #00000082 !important;
}

.rdw-link-decorator-wrapper > a {
  color: inherit !important;
}

.public-DraftStyleDefault-block {
  margin: unset !important;
}

///SCROLLBAR STYLES///

::-webkit-scrollbar {
  max-width: 10px;
  max-height: 10px;
  opacity: 0.2;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(63, 53, 109, 0.5);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(63, 53, 109);
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

///REACT TEL INPUT STYLES///

.react-tel-input .form-control {
  padding: 10.5px 14px 6.5px 60px !important;
  background: var(--cui-input-bg, #fff) !important;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95)) !important;
  border-radius: 0.375rem !important;
  background-clip: padding-box !important;
  width: 100% !important;
  border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
  font-size: 1rem !important;
}

/////////CSS VARIABLES/////////

:root {
  --clifford: #da373d;
  --signUpBg: #f0f2f5;
  --aumhumcol: #7255b0;
  --bgDark: #1e1f23;
  --signUpbgDark: #140445;
  --nav: #001128;
  --bordercolor: #3dadb8;
  --cardLight: #ebf1ff;
  --cardDark: #383838;
  --goals: #200769;
  --buttonCol: #624de3;
  --buttonColDark: #00267a;
  --tableCol: #1d1e42;
  --row1: #ffffff;
  --row1Dark: #26264f;
  --row2: #f7f6fe;
  --row2Dark: #1d1e42;
  --textHeading: #5b38a8;
  --iconCol: #330cac;
  --tableColDark: #1d1e42;
  --sideBar: #e3ebff;
  --sideBarDark: #000000;
  --tableHoverDark: #17173ffd;
  --tableHoverLight: #685cb431;
  --secondary-body-bg-light: #efd9ce;
  --secondary-body-bg-dark: #14080e;
  --header-height-with-margin: 137px;
  --header-height: 113px;
  --footer-height: 48px;
  --generic-form-max-width: 100vw;
  --transition-quick-in-out: cubic-bezier(0.1, 1.01, 0.62, 1);
}

// #Search::-webkit-search-cancel-button{
//   position:relative;
//   right:20px;
// }

body {
  overflow: auto;
}
