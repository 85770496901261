.row-item-disabled > td {
  opacity: 0.3;
}

.row-item-enabled > td {
  opacity: 1;
}

.table-actions {
  opacity: 1 !important;
}

.listing-font-medium {
  font-weight: 500 !important;
}

.smart-table-body {
}

.listing-table {
  overflow: auto;
  min-height: 250px;
}

.listing-table-head {
  overflow: scroll;
  top: 0;
  z-index: 5;
}
