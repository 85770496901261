@font-face {
  font-family: 'Mona Sans';
  src: url('../fonts/mona-sans/Mona-Sans.woff2') format('woff2 supports variations'),
    url('../fonts/mona-sans/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 100%;
}

@font-face {
  font-family: 'Hubot Sans';
  src: url('../fonts/hub-bot-sans/Hubot-Sans.woff2') format('woff2 supports variations'),
    url('../fonts/hub-bot-sans/Hubot-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 100%;
}
